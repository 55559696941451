// export const SERVER_URL = 'http://localhost:3000';
// export const SERVER_URL = 'https://dev.hybrid-reach.com';
export const SERVER_URL = 'https://api.hybrid-reach.com';
export const WATERMARK_URL = 'https://hybrid-reach.com/widget';
export const DEFAULT_WIDGET_TITLE = 'What our customers say';

export const defaultAccentColor = '#1ea1f1';

export const DEBUG_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2MzFlYzA3YzBhMzY5OGU2N2U4YTIxOCIsImlhdCI6MTcxOTk4NjIwOX0.ncBaC0sS7h_xL_BKkABP5SDB4DLp5zebeXlVAc92TWw';
// 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2MzFlYzA3YzBhMzY5OGU2N2U4YTIxOCIsImlhdCI6MTcyMTk2NTE2M30.F3a-4gW-fkU0aiDpJE92CtORUgiKYWMjKAVTm9WfXKs';
// 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2YTRlYWM2ZTE3YThjZThkNTg1YWEyMSIsImlhdCI6MTcyMjA4NDAzOX0.OYO7OI50Q81Dx-G2ku3C7uoT-eYFyotLlNCX8lT_Hg4';
// 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjlkYTllMDlkNzE4MGEwOGE0NzQ4MyIsImlhdCI6MTcyNDMwMzM5M30.hctcPT2l0Flx8YcNzw5GDLczfkB2LuDsZz58q0e_yX8';

export const MONTHLY_PRO_PRICE_ID = 'price_1PRuFAFWjpbPO0DovvU7o1bk';
export const YEARLY_PRO_PRICE_ID = 'price_1PRuFRFWjpbPO0DoWROgm757';

export const DEFAULT_GOOGLE_COMPANY_ID = 'ChIJH1Kr2OZXlVQRAUpGFja4wkQ';

export const SIGNUP_REDIRECT_URL = 'https://app.hybrid-reach.com';
// export const SIGNUP_REDIRECT_URL = 'http://localhost:3001';
